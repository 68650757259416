<template>
  <div class="text-center spinner-container">
    <img src="../../../assets/loading-animation.gif" alt="loading" class="img-fluid">
  </div>
</template>

<script>
export default {
  name: 'Spinner',
}
</script>

<style>
.spinner-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.spinner-container img{
  width: 300px;
  height: auto;
}
</style>