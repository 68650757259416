<template>
  <section id="through-the-years" class="timeline-slice">
    <b-container fluid>
      <prismic-rich-text
        uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small;"
        class="timeline-slice__heading"
        :field="slice.primary.title"
      />
      <div class="timeline-slice__content">
        <p uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small;delay:400;">{{ slice.primary.content }}</p>
        <div v-if="!isMobile()" class="timeline-slice__content__timelineSlide" uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small;delay:600;">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox inline v-model="chronologicalActive" @change="brandActive = !chronologicalActive" :aria-describedby="ariaDescribedby" name="check-button" switch>
                Chronological timeline
              </b-form-checkbox>
              <b-form-checkbox inline v-model="brandActive" @change="chronologicalActive = !brandActive" :aria-describedby="ariaDescribedby" name="check-button" switch>
                Brand timeline
              </b-form-checkbox>
            </b-form-group>
        </div>
      </div>
    </b-container>

    <brand-slider v-if="brandActive" :data="brandSliderData" />
    <chronological-slider v-if="chronologicalActive" :data="chronologicalSliderData" />

    <b-container fluid>
      <div v-if="isMobile()" class="timeline-slice__content__timelineSlide">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox v-model="chronologicalActive" @change="brandActive = !chronologicalActive" :aria-describedby="ariaDescribedby" name="check-button" switch>
            Chronological timeline
          </b-form-checkbox>
          <b-form-checkbox v-model="brandActive" @change="chronologicalActive = !brandActive" :aria-describedby="ariaDescribedby" name="check-button" switch>
            Brand timeline
          </b-form-checkbox>
        </b-form-group>
      </div>
    </b-container>

    <img uk-scrollspy="cls: uk-animation-fade uk-animation-slide-left;delay:400;" class="timeline-slice__topBottom" src="@/assets/img/through-the-ages-graphic.png" alt="topimage" />
  </section>
</template>

<script>
import DataService from "@/services/DataService";
import BrandSlider from "@/components/slices/shared/brandSlider";
import ChronologicalSlider from "@/components/slices/shared/chronologicalSlider";

export default {
  name: "TimelineSlice",
  components: {
    BrandSlider,
    ChronologicalSlider
  },
  data() {
    return {
      brandActive: false,
      chronologicalActive: true,
      brandSliderData: null,
      chronologicalSliderData: null
    };
  },
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      const response = await DataService.getTimelineItems();
      let data = response.data;
      this.brandSliderData = data.filter((item) => item.type === "brand");
      this.chronologicalSliderData = data.filter((item) => item.type === "chronological");
    },
  }
};
</script>

<style lang="scss">
.timeline-slice {
  background: #F8F8F8;
  position: relative;
  padding-top: 65px;
  padding-bottom: 165px;

  &__heading{
    margin-left: 45px;
    position: relative;
    z-index: 1;

    h2{
      font-family: 'Effra-Medium';
      color: #70706F;
      @include fslhls(40, 50, 0);
    }

    @include respond-below(xl){
      margin-left: 25px;
    }

    @include respond-below(sm){
      margin-left: 0;

      h2{
        @include fslhls(28, 36, 0);
      }
    }
  }

  &__content{
    margin-left: 150px;
    margin-top: 65px;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;

    p{
      color: #70706F;
      @include fslhls(18, 30, 0);
      margin: 0;
      max-width: 50%;
      width: 50%;

      @include respond-below(xl){
        max-width: 100%;
        width: 100%;
      }

      @include respond-below(sm){
        @include fslhls(16, 22, 0);
      }
    }

    &__timelineSlide{
      text-align: right;
      max-width: 50%;
      margin-left: auto;
      margin-top: -25px;
      text-align: center;

      .form-group{
        .custom-switch{
          padding-left: 0;
          padding-right: 2.25rem;
          .custom-control-input{
            box-shadow: none
          }
          .custom-control-label{
            @include fslhls(18, 22, 0);
            box-shadow: none;
            outline: none;
            color: #70706F;

            &::before,
            &::after{
              border-radius: 0;
              border: none !important;
            }

            &::before{
              background-color: #009FE3;
              left: auto;
              right: -2.75rem;
              height: calc(1rem + 6px);
              top: calc(0.25rem - 4px);
              width: 2.25rem;
            }

            &::after{
              background-color: #fff;
              left: auto;
              right: calc(-2rem + 3px);
              top: calc(0.25rem - 2px);
              height: calc(1rem + 2px);
              width: calc(1rem + 2px);
            }

            @include respond-below(sm){
              min-width: 180px;
            }
          }

          &:last-child{
            padding-left: 2.5rem;
            margin-top: 20px;

            @include respond-below(sm){
              padding-left: 0;
            }
          }
        }
      }

      @include respond-below(xl){
        max-width: 100%;
        margin: 0;
        margin-top: 40px;
        text-align: left;
      }

      @include respond-below(sm){
        margin-top: 20px;
      }
    }

    &__rangeSlider{
      margin-top: 80px;

      ul{
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;

        li{
          padding: 0;
          margin: 0;
          position: relative;

          &:last-child{
            position: relative;
            right: -19px;
          }

          &:first-child{
            position: relative;
            right: 19px;
          }

          &::after{
            content: '';
            position: absolute;
            bottom: -32px;
            width: 10px;
            height: 10px;
            background: #70706F;
            @include tran-y;
            z-index: 0;
          }

          @include respond-above(xxl){
            &.chronological{
              &:nth-child(3){
                right: 25px;
              }

              &:nth-child(4){
                right: 35px;
              }
            }
          }

          @include respond-above(sm){
            &.brand{
              right: 1.5rem;

              &:nth-child(3){
                right: 0;
              }

              &:nth-child(4){
                right: 1rem;
              }
            }
            &.chronological{
              right: 15px;
            }
          }

          @include respond-below(sm){
            display: none;

            &:last-child,
            &:first-child,
            &:nth-child(3){
              display: block;
            }

            &:last-child{
              right: 0;
              &::after{
                left: auto;
                right: -5px;
              }
            }
            &:first-child{
              left: 0;
              &::after{
                left: 5px;
              }
            }
          }
        }
      }

      .rangeTicker{
        position: absolute;
        top: 120%;
        opacity: 0;
        transition: opacity 0.1s ease-in-out;

        span{
          width: 40px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          background: #009FE3;
          color: #fff;
          font-size: 14px;
          display: block;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          border-radius: 6px;

          &::before{
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-bottom: 10px solid #009FE3;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            top: -10px;
            left: 50%;
            margin-left: -5px;
          }
        }

        &.active{
          opacity: 1;
        }
      }

      input[type=range]{
          -webkit-appearance: none;
          position: relative;
          z-index: 5;
      }

      input[type=range]::-webkit-slider-runnable-track {
          width: 100%;
          height: 1px;
          background: #707070;
          border: none;
          position: relative;
          z-index: -1;
      }

      input[type=range]::-webkit-slider-thumb {
          -webkit-appearance: none;
          border: none;
          height: 30px;
          width: 30px;
          border-radius: 0;
          background:#fff;
          margin-top: -14px;
          border: 5px solid #009FE3;
          position: relative;
          z-index: 10;
          box-shadow: none;
          transition: 0.4s all ease-out;
      }

      input[type=range]::-moz-range-track {
          width: 100%;
          height: 1px;
          background: #707070;
          border: none;
          position: relative;
          z-index: 0;
      }

      input[type=range]::-moz-range-thumb {
          border: none;
          height: 20px;
          width: 20px;
          border-radius: 0;
          background:#fff;
          margin-top: -14px;
          border: 5px solid #009FE3;
          position: relative;
          z-index: 10;
      }

      input[type=range]::-ms-track {
          background: transparent;
          border-color: transparent;
          border-width: 14px 0;
          color: transparent;
          width: 100%;
          height: 1px;
          cursor: pointer;
      }

      input[type=range]::-ms-fill-lower {
          border: 0;
      }

      input[type=range]::-ms-fill-upper {
          border: 0;
      }

      input[type=range]::-ms-thumb {
          width: 30px;
          height: 30px;
          border: 5px solid #009FE3;
          border-radius: 15px;
          cursor: pointer;
          margin-top: 0px;
          z-index: 10;
      }

      @include respond-below(xl){
        margin-top: 40px;
        margin-right: 20px;
        margin-left: 20px;
      }

      @include respond-below(sm){
        margin-top: 40px;
      }
    }

    @include respond-below(xl){
      margin-left: 100px;
    }

    @include respond-below(sm){
      margin-top: 40px;
      margin-bottom: 10px;
      margin-left: 0;
    }
  }

  &__cardSlider{
    padding-left: 42px;
    position: relative;
    z-index: 2;
    .splide{
      .splide__track{
        .splide__list{
          .splide__slide{
            .card{
              margin-top: 20px;
              margin-bottom: 20px;
              position: relative;
              overflow: hidden;
              -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.25);
              -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.25);
              box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.25);
              cursor: pointer;
              pointer-events: all;
              transition: .2s ease-in-out;
              border: none;

              &__header{
                background: #009FE3;
                min-height: 71px;
                display: flex;
                align-items: center;
                padding: 16px 24px;
                color: #fff;

                img{
                  max-height: 35px;
                  width: auto;
                }
              }

              &__image{
                width: 100%;
                height: 100%;
                max-height: 220px;
                object-fit: cover;
              }

              &__body{
                padding: 18px 24px;
                display: flex;
                min-height: 250px;
                justify-content: space-between;
                flex-flow: column;

                &__content{
                  color: #70706F;
                  margin-bottom: 1rem;
                  @include fslhls(18, 30, 0);
                }

                &__link{
                  margin: 0;
                  display: inline-block;
                  flex-basis: 50%;
                  width: 50%;
                  padding: 12px 10px;

                  @include respond-below(sm){
                    flex-basis: 100%;
                    width: 100%;
                  }
                }
              }
            }

            &.is-active{
              .card{
                box-shadow: 0px 0px 12px 0px #009fe3;
              }
            }

            &:hover{
              .card{
                -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.70);
                -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.70);
                box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.70);
              }
            }
          }
        }
      }
    }

    @include respond-below(xl){
      padding-left: 0;
    }
  }

  &__topBottom{
    position: absolute;
    top: -35px;
    left: -150px;
    height: 300px;
    width: auto;
    z-index: 0;

    @include respond-below(sm){
      opacity: 0.5;
    }
  }

  .splide__arrows{
    .splide__arrow{
      top: auto;
      bottom: -105px;
      border: 2px solid #70706F;
      border-radius: 0;
      background: none;
      width: 56px;
      height: 56px;
      transition: .2s ease-in-out;

      &::after{
        content: '';
        position: absolute;
        @include tran-center;
        transition: .2s ease-in-out;
        width: 20px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.705' height='27.411' viewBox='0 0 13.705 27.411'%3E%3Cpath id='Intersection_1' data-name='Intersection 1' d='M13598.46,11266.48l12.5,12.5-12.5,12.5Z' transform='translate(-13597.96 -11265.273)' fill='%2370706f' stroke='rgba(0,0,0,0)' stroke-miterlimit='10' stroke-width='1'/%3E%3C/svg%3E%0A");
      }

      svg{
        display: none;
      }

      &--next{
        left: 136px;
        right: auto;

        &:hover{
          background: #70706F;
          &::after{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.705' height='27.411' viewBox='0 0 13.705 27.411'%3E%3Cpath id='Intersection_1' data-name='Intersection 1' d='M13598.46,11266.48l12.5,12.5-12.5,12.5Z' transform='translate(-13597.96 -11265.273)' fill='%23FFFFFF' stroke='rgba(0,0,0,0)' stroke-miterlimit='10' stroke-width='1'/%3E%3C/svg%3E%0A");
          }
        }
      }

      &--prev{
        left: 56px;

        &::after{
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.705' height='27.411' viewBox='0 0 13.705 27.411'%3E%3Cpath id='Intersection_1' data-name='Intersection 1' d='M13598.46,11266.48l12.5,12.5-12.5,12.5Z' transform='translate(13611.665 11292.685) rotate(180)' fill='%2370706f' stroke='rgba(0,0,0,0)' stroke-miterlimit='10' stroke-width='1'/%3E%3C/svg%3E%0A");
        }

        &:hover{
          background: #70706F;
          &::after{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.705' height='27.411' viewBox='0 0 13.705 27.411'%3E%3Cpath id='Intersection_1' data-name='Intersection 1' d='M13598.46,11266.48l12.5,12.5-12.5,12.5Z' transform='translate(13611.665 11292.685) rotate(180)' fill='%23FFFFFF' stroke='rgba(0,0,0,0)' stroke-miterlimit='10' stroke-width='1'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
  }

  .border-down{
    @include respond-below(sm){
      display: block;
      width: calc(100% - 40px);
      height: 1px;
      background: #009FE3;
      margin: auto;
      margin-top: 60px;
    }
  }

  .side-line{
    background: #009fe3;
    height: calc(100% - 220px);
    width: 2px;
    position: absolute;
    top: auto;
    bottom: calc(100% - 21px);
    left: 110px;
    z-index: 0;

    &::after{
      content: '';
      position: absolute;
      top: -5px;
      width: 11px;
      height: 11px;
      background: #009FE3;
      left: 50%;
      transform: translateX(-50%);
    }

    @include respond-below(xl){
      left: 50px;
    }

    @include respond-below(sm){
      display: none;
    }
  }


  .custom-control-input ~ .custom-control-label::before,
  .custom-control-input ~ .custom-control-label::after{
    opacity: 0.7;
  }

  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::after{
    opacity: 1;
  }

  .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #009FE3;
  }

  .custom-control-input:focus ~ .custom-control-label::before,
  .custom-control-input:focus ~ .custom-control-label::after{
    box-shadow: none;
  }

  @include respond-below(sm){
    background: transparent;
    padding-bottom: 0;
  }
}
</style>
