<template>
<div id="homenavbar" uk-scrollspy="cls:uk-animation-slide-top-small; delay: 500">
  <header class="animate__animated" :class="{'navbar-fixed-top animate__fadeInDown' : headerfixedtop}">
    <!-- <span class="accessibility-reset" tabindex="-1"></span>
    <div class="header-accessibility-nav" tabindex="0">
      <a
        href="#/#content-main"
        tabindex="-1"
        class="header-accessibility-nav__link"
        >Skip to content</a
      >
    </div> -->
    <b-container fluid :class="`main-nav ${headerfixedtop ? 'main-nav--active' : ''}`">
      <b-link to="/" v-scroll-to="{el: '#homenavbar'}" class="main-nav__logo">
        <img src="@/assets/img/BMI-Logo.svg" alt="">
      </b-link>
      <div v-if="headerfixedtop && globalContent" class="main-nav__sectionLinks">
        <a v-for="menu in globalContent.menus" :key="menu.menu_text" href="#" v-scroll-to="{el: `#${globalSlugifyLink(menu.menu_text)}`, offset: -100}" class="scrollto">{{menu.menu_text}}</a>
      </div>
    </b-container>
  </header>
</div>

</template>

<script>
import objectFitImages from "object-fit-images";

export default {
  name: "MainHeader",
  data() {
    return {
      headerfixedtop: false
    }
  },
  computed: {
    globalContent() {
      if (this.$store.state.globalContent) {
        return this.$store.state.globalContent;
      } else {
        return "";
      }
    },
  },
  mounted() {
    /** For acessibilty related links , we only need outline style when tab is clicked */
    window.addEventListener("keydown", (e) => {
      if (e.which === 9) {
        document.body.classList.add("accessibility-using-tab");
        //console.log(document.activeElement);

        /** IE fix, as on page/route change, tabindex fix does not work in I.E to show opoup again */
        document.getElementsByClassName(
          "header-accessibility-nav"
        )[0].style.display = "block";
      }
    });
    /** For acessibilty related links , we remove tab styles when mouse clicked */
    window.addEventListener("mousedown", () => {
      document.body.classList.remove("accessibility-using-tab");
    });
    /** object-fit-images lib style to fit images IE specific */
    objectFitImages();
    // handle Scroll Functions in view
    window.addEventListener('scroll', this.handleMainnav);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleMainnav)
  },
  /** For acessibilty, we need to watch for route change and then reset the tabindex, simulating page load  */
  watch: {
    $route() {
      let resetFocusItem = document.getElementsByClassName(
        "accessibility-reset"
      )[0];
      resetFocusItem.focus();
    },
  },
  methods:{
    handleMainnav() {
      let scrollYActivate = 250
      if (window.scrollY > scrollYActivate) {
        this.headerfixedtop = true
      } else {
        this.headerfixedtop = false
      }
    }
  }
};
</script>

<style lang="scss">
/**  Style to fit images for IE using object-fit-images lib  */
.IEfix {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

header {
  position: relative;

  &.navbar-fixed-top{
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }

  .main-nav{
    width: 100%;
    background: #fff;
    padding-left: 80px;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    &__logo{
      width: 60px;
      height: auto;
      display: block;

      img{
        width: 100%;
        height: auto;
      }
    }

    &__celebratingSvg{
      margin-left: 35px;
      svg{
        transition: .2s ease-in-out;
      }

      @include respond-below(md){
        margin-left: 20px;
        max-width: 300px;

        img{
          width: 100%;
        }
      }
    }

    &__sectionLinks{
      a{
        color: #fff;
        font-family: 'Effra-Bold';
        @include fslhls(20, 24, 0);
        margin-left: 100px;

        @include respond-below(xl){
          margin-left: 50px;
        }

        @include respond-below(md){
           @include fslhls(16, 22, 0);
        }
      }

      @include respond-below(sm){
        display: none;
      }
    }

    &--active{
      background-color: #20778E;
    }

    @include respond-below(xl){
      padding-left: 20px;
    }
  }
}
</style>
