<template>
  <section class="hero-slice">
    <i
      class="side-line"
      uk-scrollspy="cls:uk-animation-fade uk-animation-slide-top-small;delay:800;"
    ></i>
    <b-container fluid>
      <b-row>
        <b-col cols="12" lg="6" class="hero-slice__content">
          <div class="inner">
            <prismic-rich-text
              :field="slice.primary.title"
              uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small;"
            />
            <prismic-rich-text
              :field="slice.primary.content"
              uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small;delay:200;"
            />
            <a
              href="#"
              v-scroll-to="{ el: '#through-the-years', offset: -100 }"
              class="btn btn--primary"
              uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small;delay:400;"
            >Through the years</a>
            <br />
            <a
              href="#"
              v-scroll-to="{ el: '#bmi-today', offset: -100 }"
              class="btn btn--primary"
              uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small;delay:400;"
            >BMI today</a>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          class="hero-slice__slider"
          uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small;"
        >
          <vue-slick-carousel
            v-if="slideItems"
            v-bind="sliderOptions"
            @init="showImage"
            @beforeChange="hideImage"
            @afterChange="showImage"
          >
            <div v-for="(item, sliceindex) in slideItems" :key="sliceindex" class="img-wrapper">
              <prismic-image :image="item.image" />
              <prismic-image
                v-if="item.graphic_image"
                :customID="`showImage-` + sliceindex"
                :image="item.graphic_image"
                :class="
                  `graphicImage showImage positionH__${isMobile() ? item.horizontal_allign : item.gphorizontal
                  } positionV__${isMobile() ? item.vertical_allign : item.gpvertical
                  } graphicImage--${sliceindex}`
                "
              />
            </div>
          </vue-slick-carousel>
          <b-card v-else>
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <img
      uk-scrollspy="cls: uk-animation-fade;"
      class="hero-slice__bottomImg"
      src="@/assets/img/hero-bottom-banner.svg"
      alt="hero-bottom-banner"
      width="100%"
    />
  </section>
</template>

<script>
import PrismicImage from "@/components/PrismicImage";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "HeroSlice",
  components: {
    VueSlickCarousel,
    PrismicImage,
  },
  data() {
    return {
      sliderOptions: {
        fade: true,
        speed: 2000,
        arrows: false,
        dots: false,
        autoplay: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplaySpeed: 5000,
      },
    };
  },
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    slideItems() {
      if (this.$store.state.globalContent && this.isMobile()) {
        return this.$store.state.globalContent.slider_block;
      } else {
        return this.slice.items;
      }
    },
  },
  methods: {
    hideImage() {
      let element = document.getElementsByClassName("prismic-svg");
      if (element) {
        element.forEach((el) => {
          let targetAllEL = el.children[0].children;
          targetAllEL.forEach((elements) => {
            // console.log(elements);
            elements.style.opacity = "0";
          });
        });
      }
    },
    showImage(slideIndex) {
      if(this.slideItems) {
        let getSvgEl = document.getElementById(`showImage-${slideIndex}`);
        if (getSvgEl) {
          let targetEl = getSvgEl.children[0].children;
          targetEl.forEach(function (el, index) {
            setTimeout(function () {
              // console.log(el);
              el.style.opacity = "1";
            }, index * 250);
          });
        }
      }

    },
  },
};
</script>

<style lang="scss">
.hero-slice {
  padding-top: 100px;
  position: relative;

  &__content {
    margin-bottom: 50px;
    order: 1;
    .inner {
      margin-left: 150px;

      > div {
        margin-bottom: 2.5rem;

        @include respond-below(xl) {
          margin-bottom: 1.5rem;
        }
      }

      h2 {
        @include fslhls(72, 85, 0);
        color: #009fe3;

        @include respond-below(xl) {
          @include fslhls(40, 60, 0);
        }
      }
      p {
        color: #70706f;
        @include fslhls(18, 30, 0);
        margin-bottom: 1.5rem;

        strong {
          font-family: "Effra-Medium";
          font-weight: normal;
        }

        @include respond-below(xl) {
          @include fslhls(16, 22, 0);
        }
      }

      @include respond-below(xl) {
        margin-left: 100px;
      }

      @include respond-below(md) {
        margin-left: 0;
      }

      @include respond-below(sm) {
        padding-bottom: 50px;
        border-bottom: 1px solid #009fe3;

        a.btn {
          width: 100%;
          display: block;
        }

        br {
          display: none;
        }
      }
    }

    @include respond-below(md) {
      order: 2;
    }

    @include respond-below(sm) {
      margin-bottom: 0;
    }
  }

  &__slider {
    order: 2;
    .slick-slide {
      .img-wrapper {
        position: relative;
        overflow: hidden;

        .prismic-image {
          width: 100%;
          height: auto;
        }

        .graphicImage {
          position: absolute;
          top: 0;
          left: 0;
          transition: all 0.5s ease-in-out;

          .prismic-image,
          .prismic-svg {
            width: auto;
            height: 100%;
            max-height: 450px;
            display: block;

            @include respond-below(sm) {
              max-height: 250px;
            }
          }

          .prismic-svg {
            g {
              path,
              rect {
                transition: all 0.5s ease-in-out;
              }
            }
          }

          &.positionH__left {
            left: 0;
            right: auto;
          }
          &.positionH__right {
            left: auto;
            right: 0;
          }
          &.positionV__top {
            top: 0;
            bottom: auto;
          }
          &.positionV__bottom {
            top: auto;
            bottom: 0;
          }

          &--5,
          &--8{
            .prismic-svg{
              height: 180px;
            }
          }
          &--7{
            .prismic-svg{
              width: 225px;
              height: 450px;
            }
          }

          &--1,
          &--2 {
            @include respond-above(sm) {
              max-height: 225px;
            }
          }

          &--2,
          &--3 {
            @include respond-below(sm) {
              max-height: 120px;
            }
          }
        }
      }
    }

    @include respond-below(md) {
      padding: 0 !important;
      order: 1;
      margin-bottom: 32px;
    }
  }

  .side-line {
    background: #009fe3;
    height: calc(100% - 100px);
    width: 2px;
    position: absolute;
    top: 0;
    bottom: auto;
    left: 110px;
    z-index: 1;

    @include respond-below(xl) {
      left: 50px;
    }

    @include respond-below(md) {
      display: none;
    }
  }

  &__bottomImg{
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
    margin-top: 25px;

    @include respond-below(md) {
      display: none;
    }
  }

  @include respond-below(xl) {
    padding: 0;
  }
}
</style>
