<template>
  <section id="bmi-today" class="text-slice">
    <b-container fluid>
      <prismic-rich-text
        uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small;"
        class="text-slice__heading"
        :field="slice.primary.title"
      />
      <div class="text-slice__inner" uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small; delay: 500;">
        <b-row>
          <b-col cols="12" lg="6" class="mb-3 mb-sm-0">
            <prismic-rich-text class="text-slice__content" :field="slice.primary.content_left" />
          </b-col>
          <b-col cols="12" lg="6">
            <prismic-rich-text class="text-slice__content" :field="slice.primary.content_right" />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <i class="side-line-top" uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small; delay: 500;" />
    <i class="side-line-bottom" uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small; delay: 1000;" />
    <img uk-scrollspy="cls: uk-animation-fade uk-animation-slide-bottom;delay:400;" class="text-slice__graphic" src="@/assets/img/map-bg-graphic.png" alt="BMI today" />
  </section>
</template>

<script>
export default {
  name: "TextSlice",
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
};
</script>

<style lang="scss">
.text-slice {
  padding: 85px 0;
  position: relative;
  overflow: hidden;

  &__heading {
    margin-left: 45px;
    position: relative;
    z-index: 1;

    h2 {
      font-family: "Effra-Medium";
      color: #70706f;
      @include fslhls(40, 50, 0);
    }

    @include respond-below(xl) {
      margin-left: 25px;
    }

    @include respond-below(sm) {
      margin-left: 0;

      h2 {
        @include fslhls(28, 36, 0);
      }
    }
  }

  &__inner {
    margin-left: 150px;
    margin-top: 65px;
    margin-bottom: 0;
    position: relative;
    z-index: 1;

    p {
      color: #70706f;
      @include fslhls(18, 30, 0);
      margin: 0;
    }

    @include respond-below(xl) {
      margin-left: 100px;
    }

    @include respond-below(sm) {
      margin-left: 0;

      p {
        @include fslhls(16, 22, 0);
      }
    }
  }

  .side-line-top {
    background: #009fe3;
    height: 67px;
    width: 2px;
    position: absolute;
    top: 0;
    bottom: auto;
    left: 110px;
    z-index: 1;

    @include respond-below(xl) {
      left: 50px;
    }

    @include respond-below(sm) {
      display: none;
    }
  }

  .side-line-bottom {
    background: #009fe3;
    height: calc(100% - 175px);
    width: 2px;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 110px;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: -5px;
      width: 11px;
      height: 11px;
      background: #009fe3;
      left: 50%;
      transform: translateX(-50%);
    }

    @include respond-below(xl) {
      left: 50px;
    }

    @include respond-below(sm) {
      display: none;
    }
  }

  &__graphic {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    max-height: 340px;
    width: auto;
    height: 100%;

    @include respond-below(sm) {
      opacity: 0.3;
      bottom: auto;
      top: 0;
      max-height: 300px;
    }
  }

  @include respond-below(sm) {
    padding-bottom: 50px;
  }
}
</style>
