<template>
  <footer class="footer" id="site-footer">
    <b-container fluid class="footer__main-footer" uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small; target: div; delay: 500;">
      <div class="footer__main-footer__left">

        <div>
          <p>Visit BMI Group</p>
          <a target="_blank" href="https://www.bmigroup.com/"><img src="@/assets/img/BMI-logo-footer.svg" alt="BMI"/></a>
        </div>

        <div>
          <p>{{getLabel('SM Title')}}</p>
          <ul>
            <li v-if="getLabel('twitter_link')">
              <a :href="getLabel('twitter_link')"><img src="@/assets/img/linked-in.svg" alt="linked-in"/></a>
            </li>
            <li v-if="getLabel('youtube_link')">
              <a :href="getLabel('youtube_link')"><img src="@/assets/img/youtube.svg" alt="youtube"/></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__main-footer__right">
        <ul>
          <li><p>© {{ footerDate }}</p></li>
          <li v-for="fmenu in globalContent.sub_footer_menu" :key="fmenu.menu_text">
            <prismic-link :link="fmenu.menu_link" >{{fmenu.menu_text}}</prismic-link>
          </li>
        </ul>
      </div>
    </b-container>
    <img
      v-if="isMobile()"
      class="footer__bottomImg"
      src="@/assets/img/footer-graphic-mobile.png"
      alt="footer-graphic"
      width="100%"
    />
    <img
    v-else
      uk-scrollspy="cls: uk-animation-fade;"
      class="footer__bottomImg"
      src="@/assets/img/footer-graphic-new.svg"
      alt="footer-graphic"
      width="100%"
    />
  </footer>
</template>

<script>
import PrismicLink from "@/components/PrismicLink";
export default {
  name: "MainFooter",
  components: {PrismicLink},
  data() {
    return {};
  },
  computed: {
    globalContent() {
      if (this.$store.state.globalContent) {
        return this.$store.state.globalContent;
      } else {
        return "";
      }
    },
    footerDate() {
      const d = new Date();
      return d.getFullYear();
    }
  },
};
</script>

<style lang="scss" scooped>
.footer {
  background: #34384F;
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;

  &__main-footer {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;

    &__left,
    &__right,
    &__center {
      flex-basis: 50%;

      p {
        margin-bottom: 30px;
      }

      p,
      a {
        @include fslhls(20, 24, 0);
        color: #fff;
      }

      a{
        max-width: 48px;
        width: 100%;
      }

      ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          margin-right: 20px;

          &:last-child {
            margin: 0;
          }
        }
      }

      @include respond-below(sm) {
        flex-basis: 100%;
      }
    }

    &__left{
      div{
        max-width: 30%;
        float: left;
        width: 100%;

        @include respond-below(md) {
          max-width: 50%;
        }
      }
    }

    &__right {
      text-align: right;
      ul {
        justify-content: flex-end;
        li {
          margin-right: 0;
          a {
            font-family: "Effra";
            max-width: inherit;
            border-bottom: 1px solid transparent;
            &:hover {
              color: #fff;
              border-color: #fff;
            }
          }

          &:last-child {
            margin-left: 50px;

            @include respond-below(sm) {
              margin: 0;
              margin-bottom: 20px;
            }
          }
        }

        @include respond-below(sm) {
          justify-content: flex-start;

          li{
            flex-basis: 50%;
          }
        }
      }

      @include respond-below(sm) {
        text-align: left;
        margin-top: 70px;
      }
    }

    @include respond-below(sm) {
      padding: 50px 35px !important;
      flex-flow: column;
      justify-content: flex-start;
    }
  }

  &__bottomImg {
    width: 100%;
    height: auto;
    position: relative;
    bottom: -1px;
  }
}
</style>
