<template>
  <prismic-link v-if="!isExternalLink" :title="title" :to="url">
    <slot />
  </prismic-link>
  <a
    v-else
    :href="url"
    :target="(linkTarget)"
    :title="title"
    rel="noopener"
  >
    <slot />
  </a>
</template>

<script>
import prismicDOM from 'prismic-dom'
import linkResolver from '../prismic/link-resolver'

export default {
  name: 'PrismicLink',
  props: {
    link: {
      type: Object,
      default () {
        return {}
      }
    },
    title: {
      type: String,
      default () {
        return null
      }
    },
    target: {
      type: String,
      default () {
        return null
      }
    }
  },
  computed: {
    isExternalLink () {
      if( this.link.link_type === 'Web' || this.link.link_type === 'Media') {
        return true
      }
      return false
    },
    url () {
      const url = prismicDOM.Link.url(this.link, linkResolver)
      return url
    },
    /**
     * Get the link target
     * @returns {String|null|*}
     */
    linkTarget () {
      if (this.link && this.link.target) {
        return this.link.target
      }
      if (this.target) {
        return this.target
      }
      return null
    }
  }
}
</script>

<style scoped>
span {
  color: white;
}
</style>
