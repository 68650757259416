<template>
  <section v-if="data">
    <!-- Range Slide -->
    <b-container
      fluid
      uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small;"
    >
      <div class="timeline-slice__content">
        <div class="timeline-slice__content__rangeSlider rich-text">
          <ul>
            <li>{{ data[0].year_readable ? data[0].year_readable : data[0].year }}</li>
            <li
              v-for="(year, yearindex) in chronological"
              :key="'yearIndex' + yearindex"
              class="chronological"
            >
              {{ year_readable ? year_readable : year }}
            </li>
            <li>{{ data[data.length - 1].year_readable ? data[data.length - 1].year_readable : data[data.length - 1].year }}</li>
          </ul>
          <div class="position-relative">
            <div v-if="!isMobile()" :class="`rangeTicker ${showTicker ? 'active' : ''}`" :style="getPosition(yearValue, data.length)" v-html="getYear(yearValue)" />
            <b-form-input
              id="yearValue"
              v-model="yearValue"
              @input="showTicker = true"
              @mouseleave="showTicker = false"
              type="range"
              min="0"
              :max="data.length - 1"
              step="0.1"
            />
          </div>
        </div>
      </div>
    </b-container>
    <!-- Range Slide -->

    <!-- Timeline Slider -->
    <div
      class="timeline-slice__cardSlider"
      uk-scrollspy="cls: uk-animation-fade uk-animation-slide-top-small;delay: 500;"
    >
      <Splide
        ref="yearSlider"
        :options="customOptions"
        @splide:mounted="splideMounted"
        @splide:dragged="syncRangeSlider"
      >
        <SplideSlide
          v-for="(item, index) in data"
          :key="item.type + index"
          :class="item.type"
        >
          <div class="card">
            <div class="card__header">
              <img :src=" require(`@/assets/img/dates/${item.type}/svg/${item.year}.svg`)" :alt="item.year" />
            </div>
            <img class="card__image" :src="
                `${
                  item.media_1_url
                    ? item.media_1_url
                    : require('@/assets/img/timeline-filler-image.jpeg')
                }`
              "
              :alt="item.type"
            />
            <div class="card__body">
              <div class="card__body__content">
                {{ item.milestone }}
              </div>
              <a
                v-if="item.content || item.external_link_url"
                href="#"
                @click.prevent="$bvModal.show('modal-' + item.year)"
                class="btn btn--primary card__body__link"
                >View more</a
              >
            </div>
          </div>
        </SplideSlide>
      </Splide>
      <i class="side-line"></i>
    </div>
    <!-- Timeline Slider -->

    <!-- Modal Box -->
    <div>
      <span class="border-down"></span>
      <b-modal v-for="(item, modalIndex) in data" :key="'modal-'+modalIndex" :id="'modal-'+item.year" centered hide-header hide-footer>
        <div class="modal-card">
          <div class="modal-card__header">
            <img :src="require(`@/assets/img/dates/${item.type}/svg/${item.year}.svg`)" :alt="item.year">
            <a href="#" @click.prevent="$bvModal.hide('modal-'+item.year)" class="crossicon">
              <svg xmlns="http://www.w3.org/2000/svg" width="32.344" height="32.344" viewBox="0 0 32.344 32.344">
                <g id="Group_558" data-name="Group 558" transform="translate(-1224.616 -1672.616)">
                  <line id="Line_2" data-name="Line 2" x2="30.576" y2="30.576" transform="translate(1225.5 1673.5)" fill="none" stroke="#fff" stroke-width="2.5"/>
                  <line id="Line_3" data-name="Line 3" x1="30.576" y2="30.576" transform="translate(1225.5 1673.5)" fill="none" stroke="#fff" stroke-width="2.5"/>
                </g>
              </svg>
            </a>
          </div>
          <img
            class="modal-card__image"
            :src="`${item.media_1_url ? item.media_1_url : require('@/assets/img/demo-image.png')}`"
            :alt="item.type"
          />
          <div class="modal-card__body">
            <div class="modal-card__body__content">
              {{ item.content }}
              <br v-if="item.media_2_url">
              <img v-if="item.media_2_url" class="modal-card__body__content__img" :src="item.media_2_url" :alt="item.type"/>
              <br v-if="item.external_link_url">
              <a v-if="item.external_link_url" target="_blank" class="modal-card__body__content__link img-fluid" :href="item.external_link_url">{{item.external_link_text ? item.external_link_text : 'Read More'}}</a>
            </div>
            <div v-if="item.vimeo_url" class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" :src="getVimeoUrl(item.vimeo_url)" frameborder="0" allowfullscreen />
            </div>
            <a href="#" class="btn btn--primary" @click.prevent="$bvModal.hide('modal-'+item.year)">Close</a>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- Modal Box -->

  </section>
  <div v-else class="text-center">
    <b-spinner label="Spinning" />
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";

export default {
  name: "ChronologicalSlider",
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      chronological : ['1923', '1966', '2000'],
      yearValue:'0',
      splideObj: null,
      showTicker: false
    };
  },
  props: {
    data: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  computed: {
    customOptions() {
      return {
        type: "slide",
        lazyLoad: "nearby",
        pagination: false,
        focus: "left",
        clones: 0,
        gap: "28px",
        padding: {
          left: "14px",
          right: "0",
        },
        perPage: 4.5,
        perMove: 1,
        start: 0,
        updateOnMove: true,
        breakpoints: {
          1024: {
            perPage: 3,
            gap: "20px",
            padding: {
              left: "20px",
              right: "0",
            },
          },
          800: {
            gap: "20px",
            padding: {
              left: "20px",
              right: "0",
            },
            perPage: 2.25,
          },
          550: {
            gap: "20px",
            padding: {
              left: "20px",
              right: "0",
            },
            perPage: 1.2,
            arrows: false,
          },
        },
      };
    }
  },
  watch:{
    yearValue: function(val) {
      if (this.splideObj) {
        this.splideObj.go(val);
      }
    }
  },
  methods: {
    splideMounted(splide) {
      this.splideObj = splide;
    },
    syncRangeSlider(splide){
      this.yearValue = splide._i + 1
    },
    getVimeoUrl(vimeoUrl) {
      var match = /vimeo.*\/(\d+)/i.exec( vimeoUrl );
      if (match){
        return `https://player.vimeo.com/video/${match[1]}`;
      }
      return false;
    },
    getYear(index) {
      let getIndex = Math.floor(index);
      let showYear = this.data[getIndex].year;
      return `<span>${showYear}</span>`
    },
    getPosition(value, maxlgth) {
      let newValue = Number( (value - 0) * 100 / ((maxlgth - 1) - 0) );
      let newPosition = 15 - (newValue * 0.3);
      return `left: calc(${newValue}% + (${newPosition}px));`
    }
  },
};
</script>

<style></style>
