import Api from "@/services/Api";

export default {
  getTimelineItems() {
    return Api().get("/timeline");
  },
  getMapItems() {
    return Api().get("/map");
  },
};
