<template>
  <section v-if="slice.primary.banner_title" class="page-banner" :style="getColor">
    <b-container>
      <b-row>
        <b-col>
          <prismic-rich-text class="page-banner__content" :field="slice.primary.banner_title"/>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'Page-Banner',
  computed: {
    getColor() {
      try {
        const bgColor = this.slice.primary.bg_color
        return {
          backgroundColor: bgColor
        }
      } catch (error) {
        return console.error('Error', error)
      }
    }
  },
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
}
</script>

<style lang="scss">
.page-banner {
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 65px;
}
</style>
