<template>
  <slices v-if="prismicDoc" :prismic-doc="prismicDoc"/>
  <Spinner v-else/>
</template>

<script>
import Slices from "@/components/slices/Slices";
import Spinner from "@/components/slices/shared/Spinner";

export default {
  name: "Page",
  components: {Slices, Spinner},
  data() {
    return {
      prismicDoc: null
    };
  },
  computed: {
    pageSlug() {
      return this.$route.params.slug
    }
  },
  methods: {
    async getContent() {
      try {
        const api = await this.$prismic.client
        const pageUid = this.$route.params.slug ? this.$route.params.slug : 'homepage'

        const results = await api.query(
            this.$prismic.predicates.at('my.page.uid', pageUid)
        )
        this.prismicDoc = results.results[0]
      } catch (ex) {
        console.error('Error loading page', ex)
      }
    }
  },
  created() {
    this.getContent();
  },
  updated() {
    this.getContent();
  },
  watch: {
    pageSlug() {
      this.prismicDoc = null
      this.getContent()
    },
  }
}
</script>

<style scoped>

</style>
