<template>
  <div id="app">
    <main-header />
      <router-view/>
    <main-footer />
  </div>
</template>

<script>
import store from './store'
import MainHeader from "@/components/MainHeader";
import MainFooter from "@/components/MainFooter";

export default {
  components: {MainFooter, MainHeader},
  async mounted() {
    this.addBugherd()
    await store.dispatch('fetchAllMenus', this.$prismic)
    if(!store.state.globalContent) {
      await store.dispatch('fetchGlobalContent', {prismic: this.$prismic})
    }
  },
  methods: {
    addBugherd() {
      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', 'https://www.bugherd.com/sidebarv2.js?apikey=0xkgpe6tqwn43qckrn7wxq')
      externalScript.setAttribute('async', 'true')
      document.head.appendChild(externalScript)
    }
  },
  watch: {
    '$route'(to) {
      document.title = to.meta.title ? `${to.meta.title} - BMI Group` : 'BMI Group'
    }
  }
}
</script>

<style lang="scss">
</style>
