import Vue from "vue";
import VueRouter from "vue-router";
import Page from "@/views/Page";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Page,
  },
  {
    path: "/homepage",
    redirect: "/",
  },
  { path: '/:slug', component: Page }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  /** If link is not a scrollTo link */
  scrollBehavior(to) {
    if (to.hash) {
      setTimeout(() => {
        let elementId = to.hash.replace(/#/, "")
        elementId = decodeURIComponent(elementId)
        const element = document.getElementById(elementId)
        console.log('element',  elementId)
        if (element && element.scrollIntoView) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }, 1000);
    }
    /** If not scrollTo link is passed */
    return { x: 0, y: 0 };
  },
});

export default router;
