import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import VueLazyload from "vue-lazyload";
import router from './router'
import store from './store'
import PrismicVue from '@prismicio/vue'
import linkResolver from './prismic/link-resolver';
import Helpers from "@/mixins/Helpers";
import { BootstrapVue } from 'bootstrap-vue'
import VueScrollTo from 'vue-scrollto'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSplide from '@splidejs/vue-splide';
import 'uikit/dist/js/uikit.min.js'

// import VueGtag from "vue-gtag";


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'uikit/dist/css/uikit.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'animate.css/animate.css';
import './assets/css/style.scss'


Vue.config.productionTip = false

Vue.use(VueLazyload)
Vue.mixin(Helpers)
Vue.use(BootstrapVue)
Vue.use(VueScrollTo)
Vue.use(VueSplide)
Vue.use(require('vue-cookies'))

Vue.use(PrismicVue, {
  endpoint: "https://bmi-commemorative-campaign.cdn.prismic.io/api/v2",
  linkResolver,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAY_oepazaMrzLFqxODMvzeAR4Fmo8WLE0',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

// Vue.use(VueGtag, {
//   // Production
//   config: { id: "G-EPVKQ3K6PT" }
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
