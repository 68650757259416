<template>
  <div v-if="prismicDoc">
    <template v-for="(slice, sliceIndex) in prismicDoc.data.body">
      <!-- Homepage slices-->
      <HeroSlice v-if="slice.slice_type === 'hero'" :key="sliceIndex" :slice="slice" />
      <PageBanner v-else-if="slice.slice_type === 'page_banner'" :key="sliceIndex" :slice="slice" />
      <TextSlice v-else-if="slice.slice_type === 'text'" :key="sliceIndex" :slice="slice" />
      <TimelineSlice v-else-if="slice.slice_type === 'timeline'" :key="sliceIndex" :slice="slice" />
      <MapSlice v-else-if="slice.slice_type === 'map'" :key="sliceIndex" :slice="slice" />
      <div v-else :key="sliceIndex">
        No component found for this slice with the slice_type {{ slice.slice_type }}
      </div>
    </template>
  </div>
</template>

<script>

import TextSlice from "@/components/slices/shared/TextSlice";
import TimelineSlice from "@/components/slices/shared/TimelineSlice";
import MapSlice from "@/components/slices/shared/MapSlice";
import PageBanner from "@/components/slices/shared/PageBanner";
import HeroSlice from "@/components/slices/shared/HeroSlice";

export default {
  name: 'Slices',
  props: {
    prismicDoc: {
      type: Object,
      default() {
        return null
      }
    }
  },
  components: {
    HeroSlice,
    PageBanner,
    MapSlice,
    TimelineSlice,
    TextSlice,
  }
}
</script>

<style scoped>

</style>
