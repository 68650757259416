<script>
import prismicDOM from 'prismic-dom'
import linkResolver from '@/prismic/link-resolver'

export default {
  name: "Helpers",
  computed: {
    browserName () {
      let isChrome = navigator.userAgent.includes('Chrome')
      const isExplorer = navigator.userAgent.includes('MSIE')
      const isFirefox = navigator.userAgent.includes('Firefox')
      let isSafari = navigator.userAgent.includes('Safari')
      const isOpera = navigator.userAgent.toLowerCase().includes('op')

      if ((isChrome) && (isSafari)) {
        isSafari = false
      }
      if ((isChrome) && (isOpera)) {
        isChrome = false
      }
      if (isSafari) {
        return 'safari'
      } else if (isChrome) {
        return 'chrome'
      } else if (isFirefox) {
        return 'firefox'
      } else if (isExplorer) {
        return 'explorer'
      } else if (isOpera) {
        return 'opera'
      } else {
        return 'unknown'
      }
    }
  },
  methods: {
    /**
     * Remove aria-hidden attribute from slick slides to avoid causing a11y issues for users
     */
    removeAriaHidden() {
      this.$nextTick(() => {
        // const slick = document.getElementById('homepage-hero')
        // const slides = document.getElementsByClassName('slick-slide')
        // slides[0].$attrs.set('aria-hidden', 'moop')
        // slides.forEach(slide => {
        //   // console.log('slick', slide.removeAttribute('aria-hidden'))
        // })
      })
    },
    /**
     * Gtag tracking
     */
    trackPageView (pageTitle) {
      try {
        this.$gtag.pageview({
          page_title: pageTitle,
        })
      } catch (ex) {
        console.error("Error tracking pageview", ex)
      }
    },
    /**
     * Get the first text line from a Prismic rich text object
     * @param richTextField
     * @returns {null|*}
     */
    getRichTextAsText (richTextField) {
      try {
        if (richTextField && richTextField[0]) {
          return richTextField[0].text
        }
      } catch (ex) {
        console.error(ex)
      }
      return null
    },
    /**
     * Get a string suitable for a HTML id
     * @param length
     * @returns {string}
     */
    getIdString (length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    /**
     * Get a URL with data from prismic "link" field type
     * @param prismicLink
     * @returns {*}
     */
    getPrismicLink (prismicLink) {
      return prismicDOM.Link.url(prismicLink, linkResolver)
    },
    /**
     * Get a global content field
     * @param fieldKey
     * @returns {string|*}
     */
    getGlobalContentField (fieldKey) {
      try {
        return this.$store.state.globalContent[fieldKey]
      } catch (ex) {
        return `Error getting global content field with key ${fieldKey} ${ex}`
      }
    },
    /**
     * Get a label from the prisimic Global Content document by label key
     * @param labelKey
     * @returns {string|null}
     */
    getLabel (labelKey) {
      try {
        let label = null
        this.$store.state.globalContent.labels.forEach((item) => {
          if (item.key === labelKey) {
            label = item.text
          }
        })
        return label || `No label found with the key ${labelKey}`
      } catch (ex) {
        return `No label found with the key ${labelKey}`
      }
    },
    /**
     * Get a nicely formated date string in the site format
     * @param dateString
     * @returns {string}
     */
    getNiceDate (dateString) {
      try {
        const d = new Date(dateString)
        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
        const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
        return `${month}/${year}`
      } catch (ex) {
        return dateString
      }
    },
    /**
     * Is the user on a mobile device?
     * @returns {boolean}
     */
    isMobile () {
      try {
        const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches
        return isMobile
      } catch (ex) {
        return false
      }
    },
    /**
     * Is the user on a tab device?
     * @returns {boolean}
     */
    isTab () {
      try {
        const isTab = window.matchMedia('only screen and (max-width: 1025px)').matches
        return isTab
      } catch (ex) {
        return false
      }
    },
    isBelowTab () {
      try {
        const isBelowTab = window.matchMedia('only screen and (max-width: 980px)').matches
        return isBelowTab
      } catch (ex) {
        return false
      }
    },
    /** Generate link based on content type */
    globalMakeLink (data) {
      /** Page link or content link */
      if (data.link_type === "Document") {
        if (data.type === "page") {
          return data.uid;
        } else {
          /** Content link, exception is feature_story*/
          let contentType = data.type;
          contentType = contentType.replace("feature_story", "story");
          return `/${contentType}/${data.uid}`;
        }
        /** Web link */
      } else {
        return data.url.replace("https://", "");
      }
    },
    /**
     * Slugify a string
     * @param string
     * @returns {string}
     */
    slugify (string) {
      try {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^w-]+/g, '') // Remove all non-word characters
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
      } catch (e) {
        return ''
      }
    },
    globalSlugifyLink(string) {
      try {
        return string
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "-");
      } catch (e) {
        return "";
      }
    },
    globalGetBackgroundColorFromName(name) {
      if (name === null || name === undefined ) return '';
      return  'sitebg-' + name.replace(/\s/g, '-').toLowerCase()
    },
    globalGetForegoundColorFromName(name) {
      if (name === null || name === undefined ) return '';
      return  'sitefg-' + name.replace(/\s/g, '-').toLowerCase()
    },
    isBrowserSafari () {
      if (this.browserName === 'safari') {
        return true
      }
      return false
    },
    /** Verifies if it is IE11 */
    globalDisableFocusForElements(className) {
      let elements = document.getElementsByClassName(className);
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute("tabindex", "-1");
      }
    },
    /** Verifies if it is IE11 */
    globalIsBrowserIE11() {
      return !!window.MSInputMethodContext && !!document.documentMode;
    },
    /**
     * Get the a portfolio item using the its slug
     * @returns {{}}
     */
    getPortfolioItemBySlug (slug) {
      const portfolioItem = this.$store.state.portfolioItems.filter((item) => {
        if (item.uid === slug) {
          return item
        }
      })
      return portfolioItem[0]
    },
    /**
     * Get an image URL from Imgix by width or height
     * @param prismicImageObject
     * @param width
     * @param height
     * @returns {string|null}
     */
    getImageUrl (prismicImageObject, width, height) {
      if (prismicImageObject) {
        const imageUrl = prismicImageObject.url
        try {
          // Get the Imgix URL without the query string
          const rawImageUrl = imageUrl.substring(0, imageUrl.indexOf('?'))

          // Now create the queryParams
          const queryParams = `?q=90${width ? `&w=${width}` : ''}${height ? `&h=${height}` : ''}`
          const newImageUrl = rawImageUrl + queryParams
          return newImageUrl
        } catch (ex) {
          console.error('Error getting image URL', { prismicImageObject, ex })
        }
      }
      return null
    }
  }
}
</script>
